import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import logo from '../images/logo.svg';
// import BackgroundImage from 'gatsby-background-image';

const Header = () => {
	const data = useStaticQuery(graphql`
		query Images {
			bgImage: file(relativePath: { eq: "hero.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1920) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		// <BackgroundImage className="header-bg" fluid={data.bgImage.childImageSharp.fluid}>
		<header>
			<div className="main-header-text">
				<div className="logo">
					<img src={logo} alt="" />
				</div>
				<h1>tata za matu</h1>
				<span>online / 1 na 1 časovi matematike i fizike</span>
				<Link to="/" className="btn">
					sajt u izradi
				</Link>
			</div>
		</header>
		// </BackgroundImage>
	);
};

export default Header;
